import React from 'react'
import { Routes, Route, useNavigate } from "react-router-dom";

import Dashboard from './pannelComponents/dashboard'
import UserList from './pannelComponents/usersList'
import AirportData from './pannelComponents/airportData'
import TermsAndCondition from './pannelComponents/termsAndCondition'
import PrivacyPolicy from './pannelComponents/privacyPolicy'
import Reviews from './pannelComponents/reviews'
import FBOClaims from './pannelComponents/fboClaims'
import AddedFBO from './pannelComponents/addedFbo'
import { useAlert } from 'react-alert';
import EventManage from './pannelComponents/EventManage';

export default function Pannel() {
  const alert = useAlert()
  let navigate = useNavigate();
  return (
    <>
      <div style={{paddingLeft: "7%", paddingTop: "0.5%", background: "#e7e7e7", border: "solid #ababab", borderWidth: "0px 0px 0px 1px"}}>
        <Routes>
          <Route path="/" element={<Dashboard navigate={navigate} alert={alert} />} />
          <Route path="/userList" element={<UserList navigate={navigate} alert={alert} />} />
          <Route path="/airportData" element={<AirportData navigate={navigate} alert={alert} />} />
          <Route path="/terms&condition" element={<TermsAndCondition navigate={navigate} alert={alert} />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy navigate={navigate} alert={alert} />} />
          <Route path="/reviewManagement" element={<Reviews navigate={navigate} alert={alert} />} />
          <Route path="/fboClaims" element={<FBOClaims navigate={navigate} alert={alert} />} />
          <Route path="/addedFbo" element={<AddedFBO navigate={navigate} alert={alert} />} />
          <Route path="/eventManagement" element={<EventManage navigate={navigate} alert={alert} />} />
          <Route path="/queries" />
        </Routes>
      </div>
    </>
  )
}
