import React, { useState, useEffect } from "react";
import {Offcanvas, Button, Table, Form, ButtonGroup, InputGroup, FormControl, Dropdown} from "react-bootstrap"
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from "axios";
import { useAlert } from "react-alert";

import { host, url } from "../../config/common";
import Loader from "./loader";
import TokenExpireHandler from "./tokenExpireHandler";

export default function AirportInfoPopup({ name, airportData, reRenderfn, navigate, ...props }) {
  const alert = useAlert()
  const [show, setShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("Facility type")
  const [stateAirportData, airportOperations] = useState(airportData);
  const customClass = {
    OffcanvasCustomWidth: {
      width: '80%'
    }
  }
  useEffect(() => {
    airportOperations(airportData)
    if (airportData.facilityType) {
      setDropdownValue(airportData.facilityType)
    }
  }, [airportData])
  const handleClose = () => {setShow(false); airportOperations(airportData)};
  const handleShow = () => {setShow(true)}

  const updateFacilityType = (value) => {
    setDropdownValue(value)
    let stateFetched = {...stateAirportData}
    stateFetched["facilityType"] = value
    airportOperations(stateFetched)
    debugger
  }

  const updateAirportData = async () => {
    try {
      // setShowLoader(true)
      alert.show("Updating...", {type: 'warning'})
      await axios({  
        method: 'patch',
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.updateIndividualAirportData}`,
        data: {stateAirportData}  
      });
      // setShowLoader(false)
      alert.show("Updated.", {type: 'success'})
      reRenderfn()
      handleClose()
    } catch (e) {
      console.log(e)
      alert.show("Something went wrong.", {type: 'error'})
      TokenExpireHandler(navigate, e)
    }
  }

  let addNewFbo = () => {
    let newFbo = {
      name : "",
      ASRI : "",
      airportID : "",
      contactNumber : "",
      website: ""
    }
    let newFboArr = stateAirportData.fbo
    newFboArr.push(newFbo) 
    airportOperations({ ...stateAirportData, fbo: newFboArr });
  }
  
  let saveOrUpdateFbo = async (fboElement) => {
    try {
      // setShowLoader(true)
      alert.show("Updating...", {type: 'warning'})
      if (fboElement._id) {
        fboElement["airportID"] = stateAirportData.airportID
        await axios({  
          method: 'patch',
          headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
          url: `${host}${url.updateFboData}`,
          data: fboElement  
        });
        // setShowLoader(false)
        alert.show("Updated.", {type: 'success'})
        reRenderfn()
        return
      }
      fboElement["airportID"] = stateAirportData.airportID
      // setShowLoader(true)
      await axios({  
        method: 'post',
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.saveFboData}`,
        data: fboElement  
      });
      // setShowLoader(false)
      alert.show("Saved.", {type: 'success'})
      reRenderfn()
    } catch (e) {
      console.log(e)
      alert.show("Something went wrong.", {type: 'error'})
      TokenExpireHandler(navigate, e)
    }
  }

  let deleteFbo = async (fboElement) => {
    try {
      if (fboElement._id) {
        setShowLoader(true)
        fboElement["airportID"] = stateAirportData.airportID
        await axios({  
          method: 'delete',
          headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
          url: `${host}${url.deleteFbo}${fboElement._id}`,
          data: fboElement  
        });
        setShowLoader(false)
        alert.show("Deleted.", {type: 'success'})
        reRenderfn()
        return
      }
      alert.show("Removed.", {type: 'success'})
      reRenderfn()
    } catch (e) {
      console.log(e);
      alert.show("Something went wrong.", {type: 'error'})
      TokenExpireHandler(navigate, e)
    }
  }
  
  let renderFBO = () => {
    return stateAirportData.fbo.map((item, index) => 
        <Table key={index} striped bordered hover size="sm">
          <thead>
            <tr>
              <th>FBO Name</th>
              <th>FBO ASRI</th>
              <th>FBO Contact</th>
              <th>FBO Website</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Form.Group controlId="formGridCity">
                  <Form.Control 
                    value={item.name} 
                    onChange={e => {
                      let newFboArr = stateAirportData.fbo
                      newFboArr[index].name = e.target.value 
                      airportOperations({ ...stateAirportData, fbo: newFboArr });
                    }}
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group controlId="formGridCity">
                  <Form.Control 
                    value={item.ASRI}
                    onChange={e => {
                      let newFboArr = stateAirportData.fbo
                      newFboArr[index].ASRI = e.target.value 
                      airportOperations({ ...stateAirportData, fbo: newFboArr });
                    }}
                  />
                </Form.Group>
              </td>
              <td>
                <Form.Group controlId="formGridCity">
                  <Form.Control 
                    value={item.contactNumber}
                    onChange={e => {
                      let newFboArr = stateAirportData.fbo
                      newFboArr[index].contactNumber = e.target.value 
                      airportOperations({ ...stateAirportData, fbo: newFboArr });
                    }}
                  />
                </Form.Group>
              </td>
              <td>
                <InputGroup className="mb-3">
                  <Form.Control 
                    value={item.website}
                    onChange={e => {
                      let newFboArr = stateAirportData.fbo
                      newFboArr[index].website = e.target.value 
                      airportOperations({ ...stateAirportData, fbo: newFboArr });
                    }}
                  />
                  <Button disabled={item.website == "" ? true : false} variant="outline-secondary" onClick={() => {window.open(item.website, '_blank')}}>
                    Visit
                  </Button>
                </InputGroup>
              </td>
              <td>
                <ButtonGroup aria-label="Basic example">
                  <Button variant="outline-primary" onClick={() => saveOrUpdateFbo(item)}>Save</Button>
                  <Button variant="outline-danger" onClick={() => deleteFbo(item)}>Delete</Button>
                </ButtonGroup>
              </td>
            </tr>
          </tbody>
        </Table>
    )                   
  }

  return (
    <>
      <Loader show={showLoader}/>
      <Button variant="outline-primary" className="me-2" onClick={handleShow}>
        <VisibilityIcon />
      </Button>
      <Offcanvas style={customClass.OffcanvasCustomWidth} show={show} onHide={handleClose} backdrop="static" {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>FBOProps Details of: {airportData.airportName}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Airport Id</th>
                  <th>Airport Name</th>
                  <th>Airport State</th>
                  <th>Airport City</th>
                  <th>Airport details</th>
                  <th>Facility type</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{airportData.airportID}</td>
                  <td>
                    <Form.Group controlId="formGridCity">
                      <Form.Control 
                        value={stateAirportData.airportName}
                        onChange={e => {
                          airportOperations({ ...stateAirportData, airportName: e.target.value });
                        }}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group controlId="formGridCity">
                      <Form.Control 
                        value={stateAirportData.state}
                        onChange={e => {
                          airportOperations({ ...stateAirportData, state: e.target.value });
                        }}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <Form.Group controlId="formGridCity">
                      <Form.Control 
                        value={stateAirportData.city}
                        onChange={e => {
                          airportOperations({ ...stateAirportData, city: e.target.value });
                        }}
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <InputGroup>
                      <FormControl
                        value={stateAirportData.airportDetails}
                        onChange={e => {
                          airportOperations({ ...stateAirportData, airportDetails: e.target.value });
                        }}
                      />
                      <Button variant="outline-secondary" onClick={() => {window.open(stateAirportData.airportDetails, '_blank')}}>
                        Visit
                      </Button>
                    </InputGroup>
                  </td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                        {dropdownValue}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => updateFacilityType("AIRPORT")}>AIRPORT</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateFacilityType("HELIPORT")}>HELIPORT</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateFacilityType("SEAPLANE BASE")}>SEAPLANE BASE</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateFacilityType("GLIDERPORT")}>GLIDERPORT</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateFacilityType("BALLOONPORT")}>BALLOONPORT</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateFacilityType("ULTRALIGHT")}>ULTRALIGHT</Dropdown.Item>
                        <Dropdown.Item onClick={() => updateFacilityType("VERTIPORT")}>VERTIPORT</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <InputGroup>
                      <FormControl
                        value={stateAirportData.location?.latitude}
                        onChange={e => {
                          airportOperations({ ...stateAirportData, location: {latitude: e.target.value, longitude: stateAirportData.location?.longitude} });
                        }}
                      />
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup>
                      <FormControl
                        value={stateAirportData.location?.longitude}
                        onChange={e => {
                          airportOperations({ ...stateAirportData, location: {latitude:  stateAirportData.location?.latitude, longitude: e.target.value} });
                        }}
                      />
                    </InputGroup>
                  </td>
                  <td>
                    <Button variant="outline-primary" onClick={() => updateAirportData()}>Update</Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            {
              renderFBO()
            }
            <Button variant="outline-info" className="me-2" onClick={addNewFbo} >Add new FBO</Button>
            <Button variant="danger" onClick={handleClose}>Close</Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}