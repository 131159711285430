import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

import Sidebar from './sidebar'
import Pannel from './pannel'
import image from './../asset/MapSidebar.png'
import './style.css'

export default function Body() {
  let navigate = useNavigate();
  const [hoverStyle, sethoverStyle] = useState({display: "none"})

  let mouseOverEvent = () => {
    sethoverStyle({display: "unset"})
  }
  
  let mouseOffEvent = () => {
    sethoverStyle({display: "none"})
  }

  return (
    <>
      <Container fluid style={{height: '100vh',margin: "0px", padding: "0px"}}>
        <Row style={{height: '100%', margin: "0px", padding: "0px"}}>
          <Col className="sidebarAnimate" style={{ height: "100%", position: "fixed", zIndex: "1"}} onMouseOver={mouseOverEvent} onMouseOut={mouseOffEvent}>
            <div>
              <Sidebar navigate={navigate} hoverStyle={hoverStyle}/>
            </div>
          </Col>
          <Col lg="12">
            <Pannel></Pannel>
          </Col>
        </Row>
      </Container>
    </>
  )
}
