import React from 'react'
import { Table, Card, InputGroup, FormControl, ButtonToolbar, ButtonGroup, Button, Form, Dropdown } from 'react-bootstrap'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CircleIcon from '@mui/icons-material/Circle';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axios from 'axios'

import { host, url } from './../../../config/common'
import UserModPopup from './../../utils/userModPopup'
import Loader from './../../utils/loader'
import TokenExpireHandler from '../../utils/tokenExpireHandler';
import AddNewUser from '../../utils/addNewUser';
import UserExcelDownloader from '../../utils/userExcelDownloader';
export default class UserList extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      allUsers: null,
      showloader: false,
      limit: 20,
      skip: 0,
      searchValue: "",
      paginationRender: 1,
      fliterButton: "All users",
      isMoreDataAvailable: false,
      showDownloader: false,
      showAddUser: false
    }
  }

  async fetchAllUserData() {
    try {
      this.setState({showloader: true})
      let allUsers = null
      let urlCheck = window.location.href.split('?')
      if (urlCheck.length > 1) urlCheck = urlCheck[1].split("=")[1]
      if (urlCheck == "active") {
        allUsers = await axios({  
          method: 'get',  
          headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
          url: `${host}${url.getAllUsers}/${this.state.limit}And${this.state.skip}`,
          params: {getUserType: "active"}
        });
        this.setState({fliterButton: "Active users"})
      }else if(urlCheck == "deactive") {
        allUsers = await axios({  
          method: 'get',  
          headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
          url: `${host}${url.getAllUsers}/${this.state.limit}And${this.state.skip}`,
          params: {getUserType: "deactive"}
        });
        this.setState({fliterButton: "Deactive users"})
      }else {
        allUsers = await axios({  
          method: 'get',  
          headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
          url: `${host}${url.getAllUsers}/${this.state.limit}And${this.state.skip}`
        });
        this.setState({fliterButton: "All users"})
      }
      if(allUsers.status == 401){
        localStorage.removeItem("user")
        return this.props.navigate("/")
      }
      if(!allUsers.data.allUsers.length) {
        this.setState({skip: this.state.skip - 20, paginationRender: this.state.paginationRender - 1})
        this.fetchAllUserData()
      }
      if (allUsers) {
        this.setState({showloader: false})
        this.setState({allUsers: allUsers.data.allUsers})
        if (!allUsers.data.isMoreDataAvailable.length) {
          this.setState({isMoreDataAvailable: true})
        }else {
          this.setState({isMoreDataAvailable: false})
        }
      }
    } catch (e) {
      console.log('error', e)
      TokenExpireHandler(this.props.navigate, e)
    }
  } 

  componentDidMount() {
    this.fetchAllUserData()
  }

  async search() {
    try {
      let key = this.state.searchValue
      if (key) {
        let searchData = await axios({  
          method: 'get',
          url: `${host}${url.searchUser}${key}`
        });
        if(searchData.status == 401){
          localStorage.removeItem("user")
          return this.props.navigate("/")
        }
        if (searchData.data.length) {
          this.setState({skip: 0})
          this.setState({allUsers: searchData.data})
          return 
        }
        this.setState({showloader: false})
        this.props.alert.show("No user found.", {type: 'info'})
        return
      }
      this.fetchAllUserData()
    } catch (e) {
      console.log('error', e)
      TokenExpireHandler(this.props.navigate, e)
    }
  }

  setSearchValue (e) {
    if (e.target.value == "") {
      this.fetchAllUserData()
    }
    let validatedStr = e.target.value.replace(/[-#*;,<>():!$%^&“+=|~€£₹?“·\{\}\[\]\\\/]/gi, "")
    this.setState({searchValue: validatedStr })
  }

  paginationAction(clickedAction) {
    this.setState({showloader: true})
    if (clickedAction == "previous") {
      this.setState({paginationRender: this.state.paginationRender - 1})
      return this.setState({skip: this.state.skip - 20}, () => this.fetchAllUserData())
    }
    if (clickedAction == "next") {
      this.setState({paginationRender: this.state.paginationRender + 1})
      this.setState({skip: this.state.skip + 20}, () => this.fetchAllUserData())
    }
  }

  paginationCount(){
    let paginations = []
    for (let index = 1; index <= this.state.paginationRender; index++) {
      paginations.push(<Button variant='light' onClick={() => {this.setState({skip: index * 20}, () => this.fetchAllUserData())}}>{index}</Button>)
    }
    
    return [paginations[paginations.length - 5], paginations[paginations.length - 4],paginations[paginations.length - 3], paginations[paginations.length - 2], paginations[paginations.length - 1]]
  }

  
  render() {
    if (!this.state.allUsers){
      return <Loader show={this.state.showloader}/>
    }
    let users =  this.state.allUsers.map((user, index) => 
        <tr key={user._id}>
          <td>{index + 1 + this.state.skip}</td>
          <td>{user.firstName ? user.firstName : "-"}</td>
          <td>{user.userName? user.userName : "-"}</td>
          <td>{user.email ? user.email : "-"}</td>
          <td>{user.isActive? <span style={{color: "#56c556"}}><CircleIcon/></span> : <span style={{color: "#cf2c2c"}}><CircleIcon/></span>}</td>
          <td>
            <UserModPopup name="editore" navigate={this.props.navigate} userDataProp={user} placement="end" reRenderFn={() => this.fetchAllUserData()} />
          </td>
        </tr>
      )
    return (
      <>
        <Loader show={this.state.showloader}/>
        <Card>
          <Card.Body>
            <Form onSubmit={(e) =>{e.preventDefault(); this.search()}}>
              <InputGroup style={{width: "500px", float: "left", marginBottom: "1%"}}>
                <FormControl
                  placeholder="Search users by Username, Firstname and Email id"
                  onChange={(e) => this.setSearchValue(e)}
                  value={this.state.searchValue}
                />
                <Button variant="outline-primary" type='submit'>
                  Search
                </Button>
              </InputGroup>
            </Form>
            {/* user dab exporter start */}
            <UserExcelDownloader show={this.state.showDownloader} onHide={() => this.setState({showDownloader: false})} />
            <AddNewUser show={this.state.showAddUser} onHide={() => this.setState({showAddUser: false})} />
            <Button size="sm" style={{float: "right", marginLeft: "5px"}} variant="outline-success" onClick={() => this.setState({showDownloader: true})}><FileDownloadIcon /> Export</Button>
            <Button size="sm" style={{float: "right", marginLeft: "5px"}} variant="outline-dark" onClick={() => this.setState({showAddUser: true})}>Add User</Button>
            {/* user dab exporter end */}
            <Dropdown style={{float: "right"}}>
              <Dropdown.Toggle size="sm" variant="outline-primary">
                {this.state.fliterButton}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => {this.props.navigate('/home/userList?getUserType=all'); this.fetchAllUserData()}}>All Users</Dropdown.Item>
                <Dropdown.Item onClick={() => {this.props.navigate('/home/userList?getUserType=active'); this.fetchAllUserData()}}>Active Users</Dropdown.Item>
                <Dropdown.Item onClick={() => {this.props.navigate('/home/userList?getUserType=deactive'); this.fetchAllUserData()}}>Deactive Users</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>First name</th>
                  <th>User name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Edit</th>
                </tr>
              </thead>

              <tbody style={this.state.showloader ? {display: "none" }: {display: "contents"}}>
                {
                  users
                }
              </tbody>
            </Table>
            <ButtonToolbar aria-label="Toolbar with button groups" style={{justifyContent: 'center'}}>
              <ButtonGroup className="me-2" aria-label="First group">
                <Button disabled={this.state.skip === 0 } onClick={() => this.paginationAction("previous")}><ArrowBackIosNewIcon /> Previous page</Button>
                {this.paginationCount()}
                <Button disabled={this.state.allUsers.length !== 20 || this.state.isMoreDataAvailable } onClick={() => this.paginationAction("next")}>Next page <ArrowForwardIosIcon/></Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Card.Body>
        </Card>
      </>
    )
  }
}
