import React, { useState } from 'react';
import DateRange from '@mui/icons-material/DateRange';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaidIcon from '@mui/icons-material/Paid';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import EventModal from './eventModal';
import DefaultImage from './../asset/dummy-image.jpg';  
import moment from 'moment';
import axios from 'axios';
import { host, url } from '../../config/common';

const EventCard = ({eventData, refreshData, ...props}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const handleEdit=() => {
    setShowEdit(true)
  }

  const handleClose=() => {
    setShowEdit(false)
  }
  const deleteEvent = async (id) => {
    try {
        let deletedEvent = await axios({
            method: 'delete',
            headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
            url: `${host}${url.deleteEvent}?id=${id}`
        });
        if (deletedEvent) {
          setshowDeleteModal(false)
          refreshData()
        }
    } catch (error) {
        console.log({error});
    }
}


  return (
    <div>
      <EventModal show={showEdit} handleClose={handleClose} eventData={eventData} refreshData={() => refreshData()}/>
      <Modal show={showDeleteModal} onHide={() => setshowDeleteModal(false)} backdrop="static">
        <Modal.Body>Are you sure you want to delete the event?</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setshowDeleteModal(false)}>
            No
          </Button>
          <Button variant="outline-danger" onClick={() => deleteEvent(eventData._id)}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Card style={{ flexDirection: "initial", marginBottom: "10px" }}>
        <Card.Img style={{ "width": "200px", height: "200px", objectFit: "contain", margin: "1%" }} src={eventData.image? eventData.image : DefaultImage} />
        <Card.Body style={{ textAlign: "initial" }}>
          <Card.Text>{eventData.name}</Card.Text>
          <p style={{ fontWeight: "300" }}><LocalAirportIcon fontSize='10px' /> {eventData.airportID} - {eventData.fboName}</p>
          <p style={{ fontWeight: "300" }}><DateRange fontSize='10px' /> {moment(eventData.startDate).format("Do MMM YYYY")} - {moment(eventData.endDate).format("Do MMM YYYY")}</p>
          <p style={{ fontWeight: "300" }}><AccessTimeIcon fontSize='10px' /> {moment(eventData.startDate).format("h:mm A")} - {moment(eventData.endDate).format("h:mm A")}</p>
          <Row>
            <Col>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ fontWeight: "300" }}><PaidIcon fontSize='10px' />  {eventData.price.amount}</p>
                <div>
                  <span style={{ fontWeight: "300", color: "#006ba3", cursor: "pointer", marginRight: "10px"}} onClick={() => handleEdit()}><DriveFileRenameOutlineIcon fontSize='10px' /> Edit</span>
                  <span style={{ fontWeight: "300", color: "red", cursor: "pointer" }} onClick={() => setshowDeleteModal(true)}><DeleteOutlineIcon fontSize='9px' /> Delete</span>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default EventCard;
