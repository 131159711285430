import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Image, InputGroup, ListGroup, Modal, Offcanvas, Row, Spinner } from 'react-bootstrap';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Datetime from 'react-datetime';
import FormData from 'form-data'
import { useAlert } from 'react-alert'


import defaultImage from "./../asset/dummy-image.jpg"
import moment from 'moment';
import { host, url } from '../../config/common';
import axios from 'axios';

const EventModal = ({ show, handleClose, eventData, refreshData, ...props }) => {
  const [file, setFile] = useState(eventData?.image ? eventData.image : defaultImage);
  const [disableButtons, setDisableButtons] = useState(false);
  const [modalEventData, setModalEventData] = useState(eventData?._id ? {...eventData} : {
    fboId: "",
    fboName: "",
    name: "",
    startDate: new Date(),
    endDate: new Date(),
    price: {
      amount: "",
      currency: ""
    },
    image: ""
  });

  const [searchResults, setSearchResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [isDisplayResults, setIsDisplayResults] = useState({display: "none"});
  const [selectedFBO, setSelectedFBO] = useState("");
  const [validated, setValidated] = useState(false);
  const [uploadingImageLoader, setUploadingImageLoader] = useState(false);

  const alert = useAlert()

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  const dateValidator = (date) => {
    let startDateValidator = moment(moment(modalEventData.startDate).format())
    let endDateValidator = moment(moment(date ? date : modalEventData.endDate).format())
    if (!endDateValidator.isAfter(startDateValidator)) {
      alert.show("Please select a valid end date", {type: "error", })
      return true
    }
    setModalEventData({...modalEventData, endDate: date})
    return false
  }

  const uploadImage = async (event) => {
    try {
      console.log({event});
      // get the selected file from the input
      const file = event.target.files[0];
      setDisableButtons(true)
      setUploadingImageLoader(true)
      // create a new FormData object and append the file to it
      const formData = new FormData();
      formData.append("image", file);
      let uploadedFile = await axios({
          method: 'post',  
          headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : "", "Content-Type": "multipart/form-data"},
          url: `${host}${url.uploadImage}`,
          data: formData
      });
      if (uploadedFile) {
        setModalEventData({...modalEventData, image: uploadedFile.data.imageUrl})
        setFile(uploadedFile.data.imageUrl)
        setDisableButtons(false)
        setUploadingImageLoader(false)
      }
    } catch (error) {
        console.log({error}); 
    }
  }

  const createEvent = async (e) => {
    try {
      e.preventDefault()
      if (!modalEventData.fboId) {
        alert.show("Please select a FBO.", {type: 'error'})
        return 
      }
      if(dateValidator()) return 
      setDisableButtons(true)
      let savedEvent = await axios({
          method: 'post',  
          headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
          url: `${host}${url.creatEventByAdmin}`,
          data: modalEventData
      });
      if (savedEvent) {
        debugger
        alert.show("Event saved", {type: 'success'})
        refreshData()
        setModalEventData({
          fboId: "",
          fboName: "",
          name: "",
          startDate: new Date(),
          endDate: new Date(),
          price: {
            amount: "",
            currency: ""
          },
          image: ""
        })
        setSelectedFBO("")
        setDisableButtons(false)
        setFile(defaultImage)
        handleClose()
        debugger
      }
    } catch (error) {
        console.log({error}); 
    }
  }
  const handleDelete = () => {
    setFile(defaultImage)
    setModalEventData({...modalEventData, image: ""})
  }

  const dateValidity = ( current ) => {
    let yesterday = moment().subtract( 1, 'day' );
      return current.isAfter( yesterday );
  };

  const dateValidityOfEndDate = ( current ) => {
    let yesterday = moment().subtract( 1, 'day' );
      return current.isAfter( yesterday );
  };

  const searchFBO = async (e) => {
    try {
        e.preventDefault()
        let searchedFBOs = await axios({
            method: 'get',  
            headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
            url: `${host}${url.searchFBO}?key=${searchInput}&limit=10&skip=0&`
        });
        if (searchedFBOs) {
          debugger
            setSearchResults(searchedFBOs.data.data)
        }
    } catch (error) {
      debugger
      console.log({error}); 
      if (error.response.status == 404) {
        alert.show("No FBO found", {type: 'error'})
      }
    }
}

const setStartDate = (date) => {
  let currentDate = moment(new Date()).format()
  debugger
  if (!date.isAfter(currentDate)) {
    setModalEventData({...modalEventData, startDate : currentDate, endDate: currentDate})
    alert.show("Please select a valid start date", {type: "error", })
    return true
  }
  setModalEventData({...modalEventData, startDate : date, endDate: date})
  return false
}

const setFBO = (item) => {
  setSearchInput("")
  setSelectedFBO(item)
  setModalEventData({...modalEventData, fboId : item._id, fboName: item.name})
  setSearchResults([])
}

  const closeAction = () => {
    if (modalEventData._id) {
      setModalEventData({...eventData})
      handleClose()
      return 
    }
    setModalEventData({
      fboId: "",
      fboName: "",
      name: "",
      startDate: new Date(),
      endDate: new Date(),
      price: {
        amount: "",
        currency: ""
      },
      image: ""
    })
    setSelectedFBO("")
    setDisableButtons(false)
    setFile(defaultImage)
    handleClose()
  }
  useEffect(() => {
    setModalEventData(eventData?._id ? {...eventData} : {
      fboId: "",
      fboName: "",
      name: "",
      startDate: new Date(),
      endDate: new Date(),
      price: {
        amount: "",
        currency: ""
      },
      image: ""
    })
    setFile(eventData?.image ? eventData.image : defaultImage);
  }, [eventData]);

  return (
    <div>
      <Offcanvas backdrop="static" style={{width: "50%"}} placement="end" show={show} onHide={closeAction} noValidate validated={validated} onSubmit={createEvent}>
        <Offcanvas.Body>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{modalEventData.fboId ? "Edit" : "Add"} Event</Offcanvas.Title>
          </Offcanvas.Header>
          <div style={{marginBottom: "10px"}}>
            <div>
              <Image width={"200px"} src={file} rounded />
              {
                uploadingImageLoader ? <Spinner animation="border" size='sm' style={{color:"#006ba3", marginLeft: "10px", marginRight: "10px"}} /> : 
                <label style={{ fontWeight: "300", color: "#006ba3", cursor: "pointer", marginRight: "10px", marginLeft: "10px"}}>
                  {
                    modalEventData.image ? 
                    <DriveFileRenameOutlineIcon fontSize='10px'/>:
                    <ControlPointIcon fontSize='10px' />
                  }
                  <input onChange={uploadImage}  style={{ display: "none" }} type="file" />
                  {modalEventData.image ? " Edit" : " Add"} 
                </label>
              }
              <span style={{ fontWeight: "300", color: "red", cursor: "pointer" }} onClick={handleDelete}><DeleteOutlineIcon fontSize='9px' /> Delete</span>
            </div>
          </div>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Name <span style={{color: "red"}}>*</span></Form.Label>
                  <Form.Control required placeholder="Event Name" onChange={(e) => setModalEventData({...modalEventData, name : e.target.value})} value={modalEventData.name} />
                </Form.Group>
                <div style={{position: "relative"}}>
                  <Form.Group style={modalEventData._id ? {display: "none"}: {}} className="mt-3">
                    <Form.Label>Select FBO</Form.Label>
                    <InputGroup>
                      <Form.Control
                          placeholder="Search FBO"
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                          // onBlur={() => {setIsDisplayResults({display: "none"})}}
                          onFocus={() => {setIsDisplayResults({display: "block"})}}
                      />
                      <Button variant="outline-primary" onClick={searchFBO}>
                      Search
                      </Button>
                    </InputGroup>
                  </Form.Group>
                  <div style={modalEventData._id || !searchInput ? {display: "none"} : {width: "100%", position: "absolute", zIndex: "1"}}>
                    <ListGroup style={{textAlign: "initial", boxShadow: "4px 5px 9px #808080"}}>
                        {
                            searchResults?.map((item) => 
                            <ListGroup.Item action key={item._id} onClick={(e) => {e.preventDefault();setFBO(item)}}>
                                {item.airportID} : {item.name}
                            </ListGroup.Item>)
                        }
                    </ListGroup>
                  </div>
                  <div className='mt-2 mb-2'>FBO Name <span style={{color: "red"}}>* </span>:  
                    <span style={{color:'#006ba3'}}>{
                      modalEventData._id ? ` ${modalEventData.airportID} : ${modalEventData.fboName}`
                      : selectedFBO ? ` ${selectedFBO.airportID} : ${selectedFBO.name}` : ""} 
                    </span>
                  </div>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label>Location <span style={{color: "red"}}>*</span></Form.Label>
                  <Form.Control required placeholder="Location" onChange={(e) => setModalEventData({...modalEventData, address : e.target.value})} value={modalEventData.address} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Select start date & time <span style={{color: "red"}}>*</span></Form.Label>
                  <Datetime 
                    initialValue={moment(modalEventData.startDate).format("MM/DD/YYYY hh:mm A")} 
                    // initialViewDate={moment(modalEventData.startDate).format("MM/DD/YYYY hh:mm A")} 
                    isValidDate={dateValidity} 
                    onClose={(date) => setStartDate(date)} 
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Select end date & time <span style={{color: "red"}}>*</span></Form.Label> 
                  <Datetime
                    initialValue={moment(modalEventData.endDate).format("MM/DD/YYYY hh:mm A")} 
                    // initialViewDate={moment(modalEventData.endDate).format("MM/DD/YYYY hh:mm A")} 
                    isValidDate={dateValidityOfEndDate} 
                    onClose={(date) => dateValidator(date)} 
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Price <span style={{color: "red"}}>*</span></Form.Label>
                  <Form.Control required type='number' min="0" placeholder="Event price" onChange={(e) => setModalEventData({...modalEventData, price : {amount : e.target.value}})} value={modalEventData.price.amount}/>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>About <span style={{color: "red"}}>*</span></Form.Label>
                  <Form.Control placeholder="About" required onChange={(e) => setModalEventData({...modalEventData, about : e.target.value})} value={modalEventData.about} as="textarea" rows={6}/>
                </Form.Group>
              </Col>
            </Row>  
            <div>
              <Button disabled={disableButtons} variant="outline-secondary" style={{marginRight: "5px"}} onClick={closeAction}>
                Cancel
              </Button>
              <Button disabled={disableButtons} variant="outline-primary" type='submit'>
                Save
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default EventModal;
