import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, ListGroup, Modal, Row } from 'react-bootstrap';
import axios from 'axios';

import EventCard from '../../utils/eventCard';
import { host, url } from '../../../config/common';
import EventModal from '../../utils/eventModal';
import Loader from '../../utils/loader';

const EventManage = ({navigate}) => {
    const [searchResults, setSearchResults] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [isDisplayResults, setIsDisplayResults] = useState({display: "none"});
    const [eventsData, setEventsData] = useState([]);
    const [selectedFBO, setSelectedFBO] = useState(searchInput ? searchInput : null);
    const [showEdit, setShowEdit] = useState(false);
    const [showloader, setShowloader] = useState(false);

    const fetchEvents = async (key) => {
        try {
            if (key) {
                setSelectedFBO(key)
            }
            setShowloader(true)
            setIsDisplayResults({display: "none"})
            let fetchedEventsData = await axios({
                method: 'get',  
                headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
                url: `${host}${url.getEventsForAdmin}?key=${key? key : ""}`
            });
            if(fetchedEventsData.status == 401){
                localStorage.removeItem("user")
                return this.props.navigate("/")
            }
            if (fetchedEventsData) {
                setShowloader(false)
                setEventsData(fetchedEventsData.data.events)
                setSearchResults([])
                if(!searchInput) setSelectedFBO(null)
            }
        } catch (error) {
            console.log({error}); 
            if(error.response.status == 401){
                localStorage.removeItem("user")
                return navigate("/")
            }
        }
    }

    const searchFBO = async (e) => {
        try {
            if (!searchInput) {
                return fetchEvents()
            }
            e.preventDefault()
            let searchedFBOs = await axios({
                method: 'get',  
                headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
                url: `${host}${url.searchFBO}?key=${searchInput}&limit=10&skip=0&`
            });
            if (searchedFBOs) {
                setSearchResults(searchedFBOs.data.data)
            }
        } catch (error) {
            console.log({error}); 
        }
    }

    const handleClose=() => {
        setShowEdit(false)
    }

    useEffect(() => {
        setIsDisplayResults(searchInput ? {display: "block"} : {display: "none"})
    }, [searchInput]);

    useEffect(() => {
        fetchEvents()
    }, [])
    
    return (
        <div style={{padding: "0px 20px"}}>
            <EventModal show={showEdit} handleClose={handleClose} refreshData={() => fetchEvents()}/>
            <Row>
                <Col>
                    <div style={{display: "flex",justifyContent: "space-between"}}>
                        <h3>Event Management</h3>
                        <Button variant='outline-primary' onClick={() => setShowEdit(true)} >Add Event</Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <div>
                    <Row>
                        <Col xs="7">
                            <Form onSubmit={searchFBO}>
                                <InputGroup className="mt-3">
                                    <Form.Control
                                        placeholder="Search FBO"
                                        onChange={(e) => setSearchInput(e.target.value)}
                                        onBlur={() => {if(!searchInput) searchFBO()}}
                                        onFocus={() => {setIsDisplayResults({display: "block"})}}
                                    />
                                    <Button variant="outline-primary" onClick={searchFBO}>
                                    Search
                                    </Button>
                                </InputGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Row style={isDisplayResults}>
                        <Col>
                            <div style={{width: "50%", position: "absolute", zIndex: "1", boxShadow: "2px 16px 28px #484848"}}>
                                <ListGroup style={{textAlign: "initial"}}>
                                    {
                                        searchResults?.map((item) => 
                                        <ListGroup.Item action key={item._id} onClick={() => fetchEvents(item.name)}>
                                            {item.airportID} : {item.name}
                                        </ListGroup.Item>)
                                    }
                                </ListGroup>
                            </div>
                        </Col>
                    </Row>
                    <Loader show={showloader}/>
                    <h2 style={{textAlign: "initial", marginTop: "10px"}}>{selectedFBO? `${selectedFBO}` : ""}</h2>
                    <Row style={{height: "82vh", overflow: "scroll "}}>
                        <Col>
                            <Row style={{marginTop: "20px"}}>
                                {
                                    eventsData?.map((item) => 
                                        <Col sm="6" key={item._id}>
                                            <EventCard eventData={item} refreshData={() => fetchEvents()}/>
                                        </Col>
                                    )
                                }
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Row>
        </div>
    );
}

export default EventManage;
