import axios from 'axios'
import React,{ useState } from 'react'
import { useAlert } from 'react-alert'
import {Modal, Button, Form, Dropdown} from 'react-bootstrap'
import { host, url } from '../../config/common'

export default function AirportAddModal(props) {
  const alert = useAlert()
  let airportDataStructure = {
    "airportID": "",
    "state": "",
    "city": "",
    "airportName": "",
    "airportDetails": "",
    "location": "",
    "facilityType": "Facility Type"
  } 
  const [dropdownValue, setDropdownValue] = useState("Facility type")
  const [latitude, setLatitude] = useState("")
  const [longitude, setLongitude] = useState("")

  const updateFacilityType = (value) => {
    setDropdownValue(value)
    let stateFetched = {...airportData}
    stateFetched["facilityType"] = value
    setairportData(stateFetched)
  }

  const [airportData, setairportData] = useState(airportDataStructure)

  const saveAirport = async () => {
    if (!airportData.airportID) {
      return alert.show("Airport Id is required.", {type: 'error'})
    }
    if (airportData.airportDetails && !isUrlValid(airportData.airportDetails)) {
      return alert.show("Airport details incorrect.", {type: 'error'})
    }
    try {
      let airportDataUpdated = {...airportData}
      airportDataUpdated.location = {
        latitude,
        longitude
      }
      console.log({airportDataUpdated});
      await axios({
        method: "post",
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.saveAirportData}`,
        data: airportDataUpdated
      })
      alert.show("Airport Saved.", {type: 'success'})
      props.reRenderfn()
      handleClose()
    } catch (e) {
      console.log(e)
      alert.show("Someting went wrong.", {type: 'error'})
    }
  }
  const handleClose = () => {
    setairportData({ airportDataStructure })
    props.onHide()
  }
  function isUrlValid(userInput) {
    var res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if(res == null) return false;
        
    return true;
  }
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Add new Airport.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Airport Id</Form.Label>
            <Form.Control 
              value={airportData.airportID}
              onChange= {e => setairportData({ ...airportData, airportID: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Airport Name</Form.Label>
            <Form.Control 
              value={airportData.airportName}
              onChange= {e => setairportData({ ...airportData, airportName: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>State</Form.Label>
            <Form.Control 
              value={airportData.state}
              onChange= {e => setairportData({ ...airportData, state: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>City</Form.Label>
            <Form.Control 
              value={airportData.city}
              onChange= {e => setairportData({ ...airportData, city: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>FAA Details</Form.Label>
            <Form.Control 
              value={airportData.airportDetails}
              onChange= {e => setairportData({ ...airportData, airportDetails: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Latitude</Form.Label>
            <Form.Control 
              // value={airportData.location.latitude}
              onChange= {e => {
                setLatitude(e.target.value)
                // let airportDataCopy = {...airportData}
                // airportDataCopy.location.latitude = e.target.value
                // setairportData(airportDataCopy)
                }
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Longitude</Form.Label>
            <Form.Control 
              // value={airportData.location.longitude}
              onChange= {e => {
                setLongitude(e.target.value)
                // let airportDataCopy = {...airportData}
                // airportDataCopy.location.longitude = e.target.value
                // setairportData(airportDataCopy)
                }
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Facility type</Form.Label>
            {/* <div style={{marginTop: "10px"}}> */}
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" size='sm'>
                  {dropdownValue}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => updateFacilityType("AIRPORT")}>AIRPORT</Dropdown.Item>
                  <Dropdown.Item onClick={() => updateFacilityType("HELIPORT")}>HELIPORT</Dropdown.Item>
                  <Dropdown.Item onClick={() => updateFacilityType("SEAPLANE BASE")}>SEAPLANE BASE</Dropdown.Item>
                  <Dropdown.Item onClick={() => updateFacilityType("GLIDERPORT")}>GLIDERPORT</Dropdown.Item>
                  <Dropdown.Item onClick={() => updateFacilityType("BALLOONPORT")}>BALLOONPORT</Dropdown.Item>
                  <Dropdown.Item onClick={() => updateFacilityType("ULTRALIGHT")}>ULTRALIGHT</Dropdown.Item>
                  <Dropdown.Item onClick={() => updateFacilityType("VERTIPORT")}>VERTIPORT</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            {/* </div> */}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => saveAirport()}>
            Save
          </Button>
          <Button variant="danger" onClick={() => handleClose()}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}