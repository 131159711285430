import React,{useState, useEffect} from 'react'
import {Modal, Button, Image, Row, Col, Form} from 'react-bootstrap'
// import avatar from './../asset/avatar.webp'
import axios from 'axios'
import { useAlert } from 'react-alert'

import { url, host } from '../../config/common'
import TokenExpireHandler from './tokenExpireHandler'

export default function AddNewUser({navigate, userProfile, ...props}) {
  const alert = useAlert()
  const [userData, userDataOperation] = useState({})
  const [isPasswordMatch, setPasswordMatch] = useState(false)
  

  let addUser = async () => {
    try {
      let updatedUser = await axios({  
        method: 'post',
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.addUser}`,
        data: userData  
      });
      if(updatedUser.status == 401){
        localStorage.removeItem("user")
        return navigate("/")
      }
      alert.show("User saved.", {type: 'success'})
      localStorage.setItem("user", JSON.stringify(updatedUser.data))
      props.onHide()
    } catch (e) {
      console.log(e);
      if(e.response?.data?.message){
        alert.show(e.response?.data?.message, {type: 'error'})
      }
      TokenExpireHandler(navigate, e)
    }
  } 
  let resetProfileData = () => {
    userDataOperation({ ...userProfile })
    props.onHide()
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Your Account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={addUser}>
          <Row>
            {/* <Col lg="4">
              <Image rounded={'true'} src={avatar} width={"100%"}/>
            </Col> */}
            <Col >
              <Form.Group className="mb-2">
                <Form.Label>First Name</Form.Label>
                <Form.Control 
                  value={userData?.firstName}
                  onChange={e => {
                    userDataOperation({ ...userData, firstName: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Last Name</Form.Label>
                <Form.Control 
                  value={userData?.lastName}
                  onChange={e => {
                    userDataOperation({ ...userData, lastName: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  value={userData?.email}
                  onChange={e => {
                    userDataOperation({ ...userData, email: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={userData?.password}
                  onChange={e => {
                    userDataOperation({ ...userData, password: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control 
                  type="password"
                  isInvalid={isPasswordMatch}
                  value={userData?.confirmPassword}
                  onChange={e => {
                    userDataOperation({ ...userData, confirmPassword: e.target.value });
                  }}
                  onBlur={() => {
                    if (userData?.password && userData?.password != userData?.confirmPassword) {
                      setPasswordMatch(true)
                    }else {
                      setPasswordMatch(false)
                    }
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='success' onClick={addUser} disabled={!(!isPasswordMatch && userData.firstName && userData.email)}>Save</Button>
        <Button variant="danger" onClick={resetProfileData}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}