import React, { useEffect, useState } from "react";
import {Offcanvas, Button, Form, Row, Col, Image, Badge, DropdownButton, Dropdown} from "react-bootstrap"
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import { useAlert } from 'react-alert'

import { host, url } from "../../config/common";
import avatar from './../asset/avatar.webp'
import Loader from "./loader";
import AirportDeletionModal from "./deletionModal";
import TokenExpireHandler from "./tokenExpireHandler";

export default function UserModPopup({ userDataProp, reRenderFn, navigate, ...props}) {
  const customClass = {
    OffcanvasCustomWidth: {
      width: '50%'
    }
  }
  const [show, setShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [deleteModalShow, setdeleteModalShow] = useState(false);
  const [userData, userDataOperation] = useState(userDataProp);
  const [userRatingslist, setUserRatingslist] = useState(userData?.ratings?.split(',').length ? userData?.ratings?.split(',') : [] )
  const [ratingsList, setRatingsList] = useState([
    'A&P Mechanic',
    'IA',
    'ATP',
    'Aviation Entusiast',
    'Aviation Medical Examiner',
    'Commercial Pilot',
    'Experimental Pilot / Builder',
    'Glider Pilot',
    'Helicopter Pilot',
    'Instrument Pilot',
    'CFII',
    'CFI',
    'Light Sport',
  ]);
  const alert = useAlert()

  if (!userDataProp) {
    userDataProp = {
      firstName: "",
      lastName: "",
      email: "",
      isPilot: "" ,
      aircraftOwner: "" ,
      profileImage: "" ,
      isAdmin: "" ,
      ratings: "" ,
      homeAirport: "" ,
      EAANumber: "" ,
      AOPANumber: "" ,
      flightClub: "" ,
      token: "" ,
      emailToken: "" 
    }
  }

  useEffect(() => {
    userDataOperation({ ...userDataProp })
  }, [userDataProp])

  const handleShow = () => setShow(true);

  const verifyUserName = async () => {
    try {
      if (!userData.userName) {
        return false
      }
      setShowLoader(true)
      let updatedUser = await axios({  
        method: 'get',
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.userNameValidation}${userData.userName}`,
        params: {_id: userData._id},
        data: userData
      });
      if (updatedUser) {
        return false
      }
    } catch (e) {
      console.log("error", e);
      debugger
      setShowLoader(false)
      alert.show("Username already taken!", {type: 'error'})
      TokenExpireHandler(navigate)
      return true
    }
  }

  const updateUserProfile = async () => {
    try {
      if(await verifyUserName()){
        return
      }
      setShowLoader(true)
      let updatedUserData = {...userData}
      updatedUserData.ratings = userRatingslist.toString()
      let updatedUser = await axios({  
        method: 'patch',
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.updateUserById}`,
        data: updatedUserData  
      });
      if (updatedUser) {
        setShowLoader(false)
        alert.show("Profile updated.", {type: 'success'})
        reRenderFn()
        handleClose()
        userDataOperation({ ...updatedUser.data })
      }
    } catch (e) {
      console.log("e", e);
      setShowLoader(false)
      alert.show("Someting went wrong!", {type: 'error'})
      TokenExpireHandler(navigate)
    }
  }

  const handleClose = () => {
    setShow(false)
    userDataOperation({ ...userDataProp })
  };

  const renderRatingChip = () => {
    return userRatingslist.map((rating, index)=> 
      <span>{
        rating == "null" ? "" :
        <Badge pill bg="info" style={{margin: "1%"}}>
          {rating}
          <CloseIcon onClick={() => {
            let userRating = [...userRatingslist]
            userRating.splice(index, 1)
            setUserRatingslist(userRating)
            }} />
        </Badge>
        }
      </span>)
  }

  const addToRatingList = (rating) => {
    if(!userRatingslist.includes(rating)){
      let ratings = [...userRatingslist]
      ratings.push(rating)
      setUserRatingslist(ratings)
    }
  }

  const deleteAccount = async () => {
    try {
      setShowLoader(true)
      let updatedUser = await axios({  
        method: 'delete',
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.deleteUserById}/${userData._id}`,
        data: userData  
      });
      if (updatedUser) {
        setShowLoader(false)
        setdeleteModalShow(false)
        alert.show("Account deleted.", {type: 'success'})
        reRenderFn()
        handleClose()
      }
    } catch (e) {
      setShowLoader(false)
      setdeleteModalShow(false)
      alert.show(e.message, {type: 'warning'})
      TokenExpireHandler(navigate)
    }
  }

  return (
    <>
      <AirportDeletionModal
        deletionfunction={() => {deleteAccount()}}
        user={userData}
        show={deleteModalShow}
        onHide={() => {setdeleteModalShow(false)}}
      />
      <Loader show={showLoader}/>
      <Button variant="outline-primary" className="me-2" onClick={handleShow}>
        <EditIcon />
      </Button>
      <Offcanvas
        style={customClass.OffcanvasCustomWidth} 
        backdrop="static"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit User Profile</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={updateUserProfile}>
            <Row>
              <Col lg="5">
                <Image rounded={'true'} src={userData.profileImage ? userData.profileImage : avatar} width={"100%"}/>
              </Col>
              <Col >  
                <Form.Group className="mb-2">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control 
                    value={userData.firstName}
                    onChange={e => {
                      userDataOperation({ ...userData, firstName: e.target.value });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control 
                    value={userData.lastName}
                    onChange={e => {
                      userDataOperation({ ...userData, lastName: e.target.value });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>User Name</Form.Label>
                  <Form.Control 
                    disabled
                    readOnly
                    value={userData.userName}
                    onChange={e => {
                      userDataOperation({ ...userData, userName: e.target.value });
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    disabled
                    value={userData.email}
                    // onChange={e => {
                    //   userDataOperation({ ...userData, email: e.target.value });
                    // }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col >
                <Form.Group className="mb-2">
                  <Form.Label>Home Airport</Form.Label>
                  <Form.Control 
                    value={userData.homeAirport}
                    onChange={e => {
                      userDataOperation({ ...userData, homeAirport: e.target.value });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>EAA Number</Form.Label>
                  <Form.Control 
                    value={userData.EAANumber}
                    onChange={e => {
                      userDataOperation({ ...userData, EAANumber: e.target.value });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>AOPA Number</Form.Label>
                  <Form.Control 
                    value={userData.AOPANumber}
                    onChange={e => {
                      userDataOperation({ ...userData, AOPANumber: e.target.value });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>Flight Club</Form.Label>
                  <Form.Control 
                    value={userData.flightClub}
                    onChange={e => {
                      userDataOperation({ ...userData, flightClub: e.target.value });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col >
                {renderRatingChip()}
                <DropdownButton id="dropdown-basic-button" title="Ratings">
                  {ratingsList.map((item => <Dropdown.Item onClick={() => addToRatingList(item)} >{item}</Dropdown.Item>))}
                </DropdownButton>
                <Form.Group className="mb-2">
                  <Form.Check
                    label="Pilot"
                    checked={userData.isPilot}
                    onChange={() => {
                      userDataOperation({ ...userData, isPilot: !userData.isPilot });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Check
                    label="Aircraft Owner"
                    checked={userData.aircraftOwner}
                    onChange={() => {
                      userDataOperation({ ...userData, aircraftOwner: !userData.aircraftOwner });
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    label="Active"
                    checked={userData.isActive}
                    onChange={() => {
                      userDataOperation({ ...userData, isActive: !userData.isActive });
                    }}
                  />
                </Form.Group>
                
              </Col>
            </Row>
          </Form>
          <Button className="m-2" variant='outline-success' onClick={() =>updateUserProfile()}>Save</Button>
          <Button className="m-2" variant='outline-danger' onClick={() => setdeleteModalShow(true)}>Delete Account</Button>
          <Button className="m-2" onClick={() =>handleClose()}>Cancel</Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}