import React from 'react'
import { ListGroup } from 'react-bootstrap'
import GridViewIcon from '@mui/icons-material/GridView';
import GroupIcon from '@mui/icons-material/Group';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LockIcon from '@mui/icons-material/Lock';
import Approval from '@mui/icons-material/Approval';
import AddedFBO from '@mui/icons-material/AirplaneTicket';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

export default class Sidebar extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      items: [
        {id: 1, icon: <GridViewIcon/>, value: 'Dashboard', active: true},
        {id: 2, icon: <GroupIcon/>, value: 'Users Management', active: false},
        {id: 3, icon: <RateReviewIcon/>, value: 'Review Management', active: false},
        {id: 4, icon: <LocalAirportIcon/>, value: 'Airports/FBO Management', active: false},
        {id: 5, icon: <LocalActivityIcon/>, value: 'Event Management', active: false},
        {id: 6, icon: <Approval/>, value: 'FBO Claims', active: false},
        {id: 7, icon: <AddedFBO/>, value: 'Added FBO', active: false},
        {id: 8, icon: <SummarizeIcon/>, value: 'Terms and Conditions', active: false},
        {id: 9, icon: <LockIcon/>, value: 'Privacy Policy', active: false},
      ],
      sidebarURL: ""
    }
  }

  updateSidebarOnURLChange(){
    if (this.state.sidebarURL != window.location.href) {
      this.sidebarUpdate()
      this.setState({sidebarURL: window.location.href})
    }
  }

  componentDidMount() {
    this.sidebarUpdate()
  }
  sidebarUpdate() {
    let sidebarLocation = window.location.href.split('/home')
    if(sidebarLocation[1] === '/userList' || sidebarLocation[1].split('?')[0] === '/userList'){
      this.setState({items: [
        {id: 1, icon: <GridViewIcon/>, value: 'Dashboard', active: false},
        {id: 2, icon: <GroupIcon/>, value: 'Users Management', active: true},
        {id: 3, icon: <RateReviewIcon/>, value: 'Review Management', active: false},
        {id: 4, icon: <LocalAirportIcon/>, value: 'Airports/FBO Management', active: false},
        {id: 5, icon: <LocalActivityIcon/>, value: 'Event Management', active: false},
        {id: 6, icon: <Approval/>, value: 'FBO Claims', active: false},
        {id: 7, icon: <AddedFBO/>, value: 'Added FBO', active: false},
        {id: 8, icon: <SummarizeIcon/>, value: 'Terms and Conditions', active: false},
        {id: 9, icon: <LockIcon/>, value: 'Privacy Policy', active: false},
      ]})
    }
    if(sidebarLocation[1] === '/airportData'){
      this.setState({items: [
        {id: 1, icon: <GridViewIcon/>, value: 'Dashboard', active: false},
        {id: 2, icon: <GroupIcon/>, value: 'Users Management', active: false},
        {id: 3, icon: <RateReviewIcon/>, value: 'Review Management', active: false},
        {id: 4, icon: <LocalAirportIcon/>, value: 'Airports/FBO Management', active: true},
        {id: 5, icon: <LocalActivityIcon/>, value: 'Event Management', active: false},
        {id: 6, icon: <Approval/>, value: 'FBO Claims', active: false},
        {id: 7, icon: <AddedFBO/>, value: 'Added FBO', active: false},
        {id: 8, icon: <SummarizeIcon/>, value: 'Terms and Conditions', active: false},
        {id: 9, icon: <LockIcon/>, value: 'Privacy Policy', active: false},
      ]})
    }
    if(sidebarLocation[1] === '/terms&condition'){
      this.setState({items: [
        {id: 1, icon: <GridViewIcon/>, value: 'Dashboard', active: false},
        {id: 2, icon: <GroupIcon/>, value: 'Users Management', active: false},
        {id: 3, icon: <RateReviewIcon/>, value: 'Review Management', active: false},
        {id: 4, icon: <LocalAirportIcon/>, value: 'Airports/FBO Management', active: false},
        {id: 5, icon: <LocalActivityIcon/>, value: 'Event Management', active: false},
        {id: 6, icon: <Approval/>, value: 'FBO Claims', active: false},
        {id: 7, icon: <AddedFBO/>, value: 'Added FBO', active: false},
        {id: 8, icon: <SummarizeIcon/>, value: 'Terms and Conditions', active: true},
        {id: 9, icon: <LockIcon/>, value: 'Privacy Policy', active: false},
      ]})
    }
    if(sidebarLocation[1] === '/privacyPolicy'){
      this.setState({items: [
        {id: 1, icon: <GridViewIcon/>, value: 'Dashboard', active: false},
        {id: 2, icon: <GroupIcon/>, value: 'Users Management', active: false},
        {id: 3, icon: <RateReviewIcon/>, value: 'Review Management', active: false},
        {id: 4, icon: <LocalAirportIcon/>, value: 'Airports/FBO Management', active: false},
        {id: 5, icon: <LocalActivityIcon/>, value: 'Event Management', active: false},
        {id: 6, icon: <Approval/>, value: 'FBO Claims', active: false},
        {id: 7, icon: <AddedFBO/>, value: 'Added FBO', active: false},
        {id: 8, icon: <SummarizeIcon/>, value: 'Terms and Conditions', active: false},
        {id: 9, icon: <LockIcon/>, value: 'Privacy Policy', active: true}
        
      ]})
    }
    if(sidebarLocation[1] === '/queries'){
      this.setState({items: [
        {id: 1, icon: <GridViewIcon/>, value: 'Dashboard', active: false},
        {id: 2, icon: <GroupIcon/>, value: 'Users Management', active: false},
        {id: 3, icon: <RateReviewIcon/>, value: 'Review Management', active: false},
        {id: 4, icon: <LocalAirportIcon/>, value: 'Airports/FBO Management', active: false},
        {id: 5, icon: <LocalActivityIcon/>, value: 'Event Management', active: false},
        {id: 6, icon: <Approval/>, value: 'FBO Claims', active: false},
        {id: 7, icon: <AddedFBO/>, value: 'Added FBO', active: false},
        {id: 8, icon: <SummarizeIcon/>, value: 'Terms and Conditions', active: false},
        {id: 9, icon: <LockIcon/>, value: 'Privacy Policy', active: false},
      ]})
    }
    if(sidebarLocation[1] === '/reviewManagement'){
      this.setState({items: [
        {id: 1, icon: <GridViewIcon/>, value: 'Dashboard', active: false},
        {id: 2, icon: <GroupIcon/>, value: 'Users Management', active: false},
        {id: 3, icon: <RateReviewIcon/>, value: 'Review Management', active: true},
        {id: 4, icon: <LocalAirportIcon/>, value: 'Airports/FBO Management', active: false},
        {id: 5, icon: <LocalActivityIcon/>, value: 'Event Management', active: false},
        {id: 6, icon: <Approval/>, value: 'FBO Claims', active: false},
        {id: 7, icon: <AddedFBO/>, value: 'Added FBO', active: false},
        {id: 8, icon: <SummarizeIcon/>, value: 'Terms and Conditions', active: false},
        {id: 9, icon: <LockIcon/>, value: 'Privacy Policy', active: false},
      ]})
    }
    if(sidebarLocation[1] === '/fboClaims'){
      this.setState({items: [
        {id: 1, icon: <GridViewIcon/>, value: 'Dashboard', active: false},
        {id: 2, icon: <GroupIcon/>, value: 'Users Management', active: false},
        {id: 3, icon: <RateReviewIcon/>, value: 'Review Management', active: false},
        {id: 4, icon: <LocalAirportIcon/>, value: 'Airports/FBO Management', active: false},
        {id: 5, icon: <LocalActivityIcon/>, value: 'Event Management', active: false},
        {id: 6, icon: <Approval/>, value: 'FBO Claims', active: true},
        {id: 7, icon: <AddedFBO/>, value: 'Added FBO', active: false},
        {id: 8, icon: <SummarizeIcon/>, value: 'Terms and Conditions', active: false},
        {id: 9, icon: <LockIcon/>, value: 'Privacy Policy', active: false},
      ]})
    }
    if(sidebarLocation[1] === '/addedFbo'){
      this.setState({items: [
        {id: 1, icon: <GridViewIcon/>, value: 'Dashboard', active: false},
        {id: 2, icon: <GroupIcon/>, value: 'Users Management', active: false},
        {id: 3, icon: <RateReviewIcon/>, value: 'Review Management', active: false},
        {id: 4, icon: <LocalAirportIcon/>, value: 'Airports/FBO Management', active: false},
        {id: 5, icon: <LocalActivityIcon/>, value: 'Event Management', active: false},
        {id: 6, icon: <Approval/>, value: 'FBO Claims', active: false},
        {id: 7, icon: <AddedFBO/>, value: 'Added FBO', active: true},
        {id: 8, icon: <SummarizeIcon/>, value: 'Terms and Conditions', active: false},
        {id: 9, icon: <LockIcon/>, value: 'Privacy Policy', active: false},
      ]})
    }
    if(sidebarLocation[1] === '/eventManagement'){
      this.setState({items: [
        {id: 1, icon: <GridViewIcon/>, value: 'Dashboard', active: false},
        {id: 2, icon: <GroupIcon/>, value: 'Users Management', active: false},
        {id: 3, icon: <RateReviewIcon/>, value: 'Review Management', active: false},
        {id: 4, icon: <LocalAirportIcon/>, value: 'Airports/FBO Management', active: false},
        {id: 5, icon: <LocalActivityIcon/>, value: 'Event Management', active: true},
        {id: 6, icon: <Approval/>, value: 'FBO Claims', active: false},
        {id: 7, icon: <AddedFBO/>, value: 'Added FBO', active: false},
        {id: 8, icon: <SummarizeIcon/>, value: 'Terms and Conditions', active: false},
        {id: 9, icon: <LockIcon/>, value: 'Privacy Policy', active: false},
      ]})
    }
  }
  
  pannelSelection (key, value) {
    let navigate =  this.props.navigate
    let newSidebarItemsList = []
    
    this.state.items.forEach((element) => {
      element.active = false
      if (element.value === value) {
        element.active = true
      }
      newSidebarItemsList.push(element)
    })
    this.setState({items: newSidebarItemsList})
    switch (value) {
      case 'Dashboard':
        navigate('/home/')
        break;
      case 'Users Management':
        navigate('/home/userList')
        break;    
      case 'Airports/FBO Management':
        navigate('/home/airportData')
        break;    
      case 'Query Management':
        navigate('/home/airportData')
        break;    
      case 'Terms and Conditions':
        navigate('/home/terms&condition')
        break;    
      case 'Privacy Policy':
        navigate('/home/privacyPolicy')
        break;    
      case 'Review Management':
        navigate('/home/reviewManagement')
        break;
      case 'FBO Claims':
        navigate('/home/fboClaims')
        break; 
      case 'Added FBO':
        navigate('/home/addedFbo')
        break;
      case 'Event Management':
        navigate('/home/eventManagement')
        break;    
      default:
        break;
    }
  }

  render() {
    this.updateSidebarOnURLChange()
    return (
      <>
        <ListGroup style={this.props.hoverStyle.display == "none" ? {} : {textAlign: 'left', backgroundColor: "white"}}>
          {
            this.state.items.map((item) => 
              <ListGroup.Item style={item.active?{ border: "0px", backgroundColor: "#2283b5"} : {border: "0px", color: "#469cc6"}} key={item.id} action active={item.active} onClick={() => this.pannelSelection(item.key, item.value)}>
                <span>{item.icon}</span> <span style={this.props.hoverStyle}>{item.value}</span>
              </ListGroup.Item>
            )  
          }
        </ListGroup>
      </>
    )
  }
}
