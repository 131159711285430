import React from 'react'
import { Container, Row, Col, Card, Table, Image, Tab, Tabs, Alert } from 'react-bootstrap'
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import moment from 'moment';
import airplane from "./../../asset/icons/airplane.png"
import cardBack from "./../../asset/icons/cardBack.png"
import airport from "./../../asset/icons/airport.png"
import deactiveUser from "./../../asset/icons/deactiveUser.png"
import activeUser from "./../../asset/icons/activeUser.png"

import avatar from './../../asset/avatar.webp'
import Loader from '../../utils/loader';
import { host, url } from '../../../config/common';
import './../style.css'
import TokenExpireHandler from '../../utils/tokenExpireHandler';

export default class Dashboard extends React.Component{
  constructor(props){
    super(props)

    this.state = {
      dataFetched: false,
      userData: [],
      userGraphData: [],
      activeUsers: 0,
      deactiveUsers: 0,
      fboCount: 0,
      airportCount: 0,
      showloader: false,
      lineData: {},
      tableData: null,
      fontSizeMediaQuery: {
        font: "1vw",
        icon: "1.9vw"
      },
      chartHeight: "125px",
      chartOptions:{
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'probability'
            }
          }]
        } 
      }
    }
  }

  maintainGraphData() {
    let lindeData = {
      labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      datasets: [
        {
          label: `New users Engagement of ${moment().format('MMMM')}`,
          fill: true,
          lineTension: 0.5,
          backgroundColor: 'rgb(34 131 181 / 46%)',
          borderColor: 'rgb(34 131 181',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 10,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 20,
          data: [0,0,0,0,0,0,0]
        }
      ]
    }
    // getting current month days
    let now = new Date();
    let cuttentMonthDays = new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();
    let days = []
    let presetGraphData = []
    for (let index = 1; index <= cuttentMonthDays; index++) {
      days.push(index)
      presetGraphData.push(0)
    }
    lindeData.labels = days
    this.state.userGraphData.forEach(element => {
      if (parseInt(element.dateDMY.split("-")[1]) == moment().month() + 1) {
        presetGraphData[parseInt(element.dateDMY.split("-")[0]) -1 ] = element.count
      }
    });
    lindeData.datasets[0].data = presetGraphData
    this.setState({lineData: lindeData})
    this.setState({dataFetched: true})
  }

  async fetchDashboardData() {
    try {
      this.setState({showloader: true})
      let dashboardData = await axios({  
        method: 'get',  
        headers: {'x-access-token': JSON.parse(localStorage.getItem('user'))?.token ? JSON.parse(localStorage.getItem('user')).token : ""},
        url: `${host}${url.getDashboardData}`
      });
      if (dashboardData) {
        this.setState({showloader: false})
        this.setState({
          userData: dashboardData.data.userData,
          deactiveUsers: dashboardData.data.deactiveUsers,
          activeUsers: dashboardData.data.activeUsers,
          fboCount: dashboardData.data.fboData,
          airportCount: dashboardData.data.airportData,
          userGraphData: dashboardData.data.userGraphData
        })
        this.maintainGraphData()
        this.updateTableData("today")
        return 
      }
      this.setState({showloader: false})
    } catch (e) {
      TokenExpireHandler(this.props.navigate, e)
      console.log('error', e)
      this.props.alert.show("Someting went wrong.", {type: 'warning'})
    }
  }

  renderUserTable() {
    if (!this.state.tableData || !this.state.tableData.length) {
      return (
        <Alert variant="info">
          No data found!
        </Alert>
      )
    }
    return(
      <Table striped bordered hover>
        <thead>
            <tr>
              <th>Profile Image</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>User Name</th>
              <th>Email</th>
            </tr>
        </thead>
        <tbody>
          {
            this.state.tableData.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    <Image rounded={'true'} src={ item.profileImage? item.profileImage: avatar} width={"40px"}/>
                  </td>
                  <td>{item.firstName ? item.firstName : "-"}</td>
                  <td>{item.lastName ? item.lastName : "-"}</td>
                  <td>{item.userName ? item.userName : "-"}</td>
                  <td style={{width: "230px", height: "50px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                    <div style={{width: "530px", height: "50px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                      {item.email}
                    </div>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </Table>
    )
  }

  chart() {
    if (!this.state.dataFetched) {
      return(
        "Chart"
      )
    }
    return(
      <Line height={this.state.chartHeight} ref="chart" data={this.state.lineData} options={this.state.chartOptions} />
    )
  }

  updateTableData(key) {
    let userData = []
    switch (key) {
      case "thisWeek":
        this.state.userData.forEach(element => {
          if (moment(element.createdAt).date() >= moment().date() - 7) {
            userData.push(element)
          }
        });
        this.setState({tableData: userData})
        break;
      case "thisMonth":
        this.setState({tableData: this.state.userData})
        break;
      case "today":
        this.state.userData.forEach(element => {
          if (moment(element.createdAt).date() == moment().date()) {
            userData.push(element)
          }
        });
        this.setState({tableData: userData})
        break;
    }
  }

  componentDidMount() {
    this.fetchDashboardData()
    if (window.innerWidth < 1314) {
      this.setState({fontSizeMediaQuery: {
        font: "1vw",
        icon: "2vw"
      }})
    }

    if (window.innerWidth < 990) {
      this.setState({fontSizeMediaQuery: {
        font: "2vw",
        icon: "3vw"
      }})
    }

    if (window.innerWidth < 1176) {
      this.setState({chartHeight: "115px"})
    }
  }
  render() {
    return (
      <>
        <Loader show={this.state.showloader}/>
        <div style={{color: "#469cc7"}}>
          <Container>
            <Row>
              <Col lg={8} md={12}>
                <Card>
                  <Card.Body style={{paddingLeft: "28px"}}>
                    <p className='graphNotation'>No. of users</p>
                    {this.chart()}
                    <span>Date</span>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={4}>
                <Row>
                  <Col>
                    <Card text="light" onClick={() => this.props.navigate('/home/userList?getUserType=active')} style={{cursor: "pointer",backgroundImage: "linear-gradient(to right bottom, rgb(6 73 108), rgb(19 113 161), rgb(53 188 255))", textAlign: "left"}}>
                      <img className='cardBackground' src={cardBack} alt="activeUsers" />
                      <Card.Body>
                        <div style={{fontSize: this.state.fontSizeMediaQuery.font}}>
                          Active Users
                        </div>
                        <Card.Title>{this.state.activeUsers}</Card.Title>
                        <div style={{marginTop: "15%"}}>
                          <img style={{width: this.state.fontSizeMediaQuery.icon, float: "right"}} src={activeUser} alt="activeUsers" />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card text="light" onClick={() => this.props.navigate('/home/userList?getUserType=deactive')} style={{cursor: "pointer",backgroundImage: "linear-gradient(to right bottom, rgb(6 73 108), rgb(19 113 161), rgb(53 188 255))", textAlign: "left"}}>
                      <img className='cardBackground' src={cardBack} alt="activeUsers" />
                      <Card.Body>
                        <div style={{fontSize: this.state.fontSizeMediaQuery.font}}>
                          Deactive Users
                        </div>
                        <Card.Title>{this.state.deactiveUsers}</Card.Title>
                        <div style={{marginTop: "15%"}}>
                          <img style={{width: this.state.fontSizeMediaQuery.icon, float: "right"}} src={deactiveUser} alt="activeUsers" />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card text="light" onClick={() => this.props.navigate('/home/airportData')} style={{cursor: "pointer",backgroundImage: "linear-gradient(to right bottom, rgb(6 73 108), rgb(19 113 161), rgb(53 188 255))", textAlign: "left", marginTop: "9%"}}>
                      <img className='cardBackground' src={cardBack} alt="activeUsers" />
                      <Card.Body>
                        <div style={{fontSize: this.state.fontSizeMediaQuery.font}}>
                        Total FBOs
                        </div>
                        <Card.Title>{this.state.fboCount}</Card.Title>
                        <div style={{marginTop: "15%"}}>
                          <img style={{width: this.state.fontSizeMediaQuery.icon, float: "right"}} src={airport} alt="activeUsers" />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card text="light" onClick={() => this.props.navigate('/home/airportData')} style={{cursor: "pointer",backgroundImage: "linear-gradient(to right bottom, rgb(6 73 108), rgb(19 113 161), rgb(53 188 255))", textAlign: "left", marginTop: "9%"}}>
                      <img className='cardBackground' src={cardBack} alt="activeUsers" />
                      <Card.Body>
                        <div style={{fontSize: this.state.fontSizeMediaQuery.font}}>
                        Total Airports
                        </div>
                        <Card.Title>{this.state.airportCount}</Card.Title>
                        <div style={{marginTop: "15%"}}>
                          <img style={{width: this.state.fontSizeMediaQuery.icon, float: "right"}} src={airplane} alt="activeUsers" />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card text="light" onClick={() => this.props.navigate('/home/userList')} style={{cursor: "pointer",backgroundImage: "linear-gradient(to right bottom, rgb(6 73 108), rgb(19 113 161), rgb(53 188 255))", textAlign: "left", marginTop: "4%"}}>
                      <img className='cardBackground' src={cardBack} alt="activeUsers" />
                      <Card.Body>
                        <div style={{fontSize: this.state.fontSizeMediaQuery.font, float: "left"}}>
                        Total Users
                        </div>
                        <Card.Title style={{float: "right"}}>{this.state.activeUsers + this.state.deactiveUsers}</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card  style={{marginTop: "3%", marginBottom: "3%"}}>
                  <Card.Body>
                    <Card.Title>
                      New Users
                    </Card.Title>
                    <Tabs variant="pills" defaultActiveKey="today" onSelect={(eventKey) => this.updateTableData(eventKey)} className="mb-3">
                      <Tab eventKey="today" style={{height: "330px", overflow: "auto"}} title="Today">
                        {this.renderUserTable()}
                      </Tab>
                      <Tab eventKey="thisWeek" style={{height: "320px", overflow: "auto"}} title="This week">
                        {this.renderUserTable()}
                      </Tab>
                      <Tab eventKey="thisMonth" style={{height: "320px", overflow: "auto"}} title="This month">
                        {this.renderUserTable()}
                      </Tab>
                    </Tabs>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    )
  }
}
